import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import axios from 'axios';
import env from "react-dotenv";
import './css/App.css'

export default function App() {

  return (
    <Router>
      <Navigation />
    </Router>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function authURL() {
  const url = `https://dev.are.na/oauth/authorize?client_id=${env.CLIENT_ID}&redirect_uri=${env.REDIRECT_URI}&response_type=code`
  return url
}

function Navigation({ auth }) {
  let query = useQuery()

  return (
    <div>
      <h1>Ginkgo</h1>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/account">Account</Link>
          </li>
          <li>
            <Link to="/settings">Settings</Link>
          </li>
        </ul>
      </nav>

      <Child name={query.get("code")} auth={auth} />
    </div>
  );
}
  
function Child({ name }) {
  const [auth, setAuth] = useState(false)
  const location = useLocation()
  const [code, setCode] = useState('')
  const [token, setToken] = useState('')
  const history = useHistory()

  useEffect(() => {
    let codeParam = new URLSearchParams(location.search).get("code") 
    if (codeParam) {
      setAuth(true)
      setCode(codeParam)

      history.push("/login")
      console.log(process.env.NODE_ENV)
      const authProxyUrl = `${process.env.NODE_ENV === 'development' ? env.CORS_PROXY_URL : '' }https://ginkgo.now.sh/api/get-access-token?code=${codeParam}` 

      // axios.post(`${window.location.origin}/api/get-access-token?code=${codeParam}`)
      axios.post(authProxyUrl)
        .then(res => {
          setToken(res.data.access_token)
          console.log(res.data)
        })
    }
  }, [code, history, location, token])


  return (
    <div>
      <div>
        <h3><code>origin:</code> {window.location.origin}</h3>
        <h3><code>client id:</code> {env.CLIENT_ID}</h3>
        <h3><code>secret (should be false):</code> {env.CLIENT_SECRET ? 'true' : 'false'}</h3>
        <h3><code>code:</code> {code} </h3>
        <h3><code>redirect uri:</code> {env.REDIRECT_URI}</h3>
        <h3><code>auth token:</code> {token}</h3>
      </div>

      {auth ? (
          <button>Signout</button>
        ) : (
          <a href={authURL()}>Authenticate on Are.na</a>
      )}
    </div>
  );
}
